/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { navigate } from "gatsby";
import { useIsLoggedIn, useMyInfo } from "../hooks/user.hooks.ts";
import {
  acceptInvitation,
  removeUserFromScenario,
  validateToken,
} from "../api/scenario";
import LoadingSpinner from "../components/loading-spinner";
import CustomButton, { CustomButtonOutLine } from "../components/custom-button";
import BackToHomeButton from "../components/back-to-home-button";
import { logout } from "../utils/auth";

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 1:
      return "Token expired";
    case 2:
      return "You are already accepted the invitation";
    case 3:
      return "Invited user are not existed";

    default:
      return "The link is invalid";
  }
};

const AcceptInvitation = () => {
  const isUserLoggedIn = useIsLoggedIn();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const [errorMessage, setErrorMessage] = useState("");
  const {
    mutateAsync: validateTokenMutate,
    isLoading: validateLoading,
  } = useMutation((_token) => validateToken(_token));
  const { data: userInfo = {} } = useMyInfo();
  const {
    mutateAsync: acceptInvitationMutate,
    isSuccess: acceptSuccess,
    isLoading: acceptLoading,
  } = useMutation((_token) => acceptInvitation(_token));
  const {
    mutateAsync: rejectMutate,
    isLoading: rejectLoading,
    isSuccess: rejectSuccess,
  } = useMutation(({ userId, scenarioId }) =>
    removeUserFromScenario(scenarioId, userId)
  );
  const isSuccess = rejectSuccess || acceptSuccess;
  const [redirectTime, setRedirectTime] = useState(5);
  const [inviteData, setInviteData] = useState({});
  const _validateToken = async () => {
    try {
      const res = await validateTokenMutate(token);
      setInviteData(res);
    } catch (error) {
      if (error.data.errorCode === 3) {
        const redirect = `/signup?redirect=${encodeURIComponent(
          `/accept-invitation${window.location.search}`
        )}`;
        logout(redirect);
      } else {
        setErrorMessage(getErrorMessage(error.data.errorCode));
      }
    }
  };

  const acceptInvite = async () => {
    try {
      await acceptInvitationMutate(token);
      setInterval(() => {
        setRedirectTime((value) => value - 1);
      }, 1000);
      setTimeout(() => {
        navigate(`/finance/buying-power?scenarioId=${inviteData?.scenarioId}`);
      }, 5000);
    } catch (error) {
      setErrorMessage(getErrorMessage(error.data.errorCode));
    }
  };

  const rejectInvite = async () => {
    try {
      await rejectMutate({
        userId: userInfo.id,
        scenarioId: inviteData.scenarioId,
      });
      setInterval(() => {
        setRedirectTime((value) => value - 1);
      }, 1000);
      setTimeout(() => {
        navigate(`/finance/buying-power?scenarioId=${inviteData?.scenarioId}`);
      }, 5000);
    } catch (error) {
      setErrorMessage(getErrorMessage(error.data.errorCode));
    }
  };

  if (!isUserLoggedIn) {
    navigate(
      `/signup?redirect=${encodeURIComponent(
        `/accept-invitation${window.location.search}`
      )}`
    );
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      _validateToken(token);
      if (!token) {
        setErrorMessage("Invalid link");
      }
    }
    return () => {};
  }, [token, isUserLoggedIn]);

  const renderButtonConfirm = () => (
    <div className="submit-button-group">
      <CustomButton
        isLoading={acceptLoading}
        label="Accept"
        onKeyPress={() => {}}
        onClick={acceptInvite}
      />
      <CustomButtonOutLine
        isLoading={rejectLoading}
        label="Reject"
        onKeyPress={() => {}}
        onClick={rejectInvite}
      />
    </div>
  );

  const renderContent = () =>
    errorMessage ? (
      <div className="flex flex-col items-center justify-center">
        <div className="confirmation-content">
          <div className="confirmation-content__header">Invalid request</div>
          <div>{errorMessage}</div>
        </div>
        <div className="back-to-login-button-container">
          <BackToHomeButton />
        </div>
      </div>
    ) : (
      <div className="flex flex-col items-center">
        {isSuccess ? (
          <div className="flex flex-col items-center">
            <h4>Successfully!</h4>
            <span>Back to home in {redirectTime}</span>
          </div>
        ) : (
          <>
            <div className="confirmation-content__header">
              Do you want to join {inviteData?.scenario?.options?.name}?
            </div>
            {renderButtonConfirm()}
          </>
        )}
      </div>
    );

  return (
    <div
      className="flex-center confirmation-content "
      style={{ minHeight: "100vh" }}
    >
      {validateLoading ? (
        <>
          <LoadingSpinner />
          <span>Validating...</span>
        </>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default AcceptInvitation;
