import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "gatsby";
import React from "react";

const BackToHomeButton = () => {
  return (
    <div>
      <Link className="back-to-login" style={{ alignSelf: "center" }} to="/">
        <ArrowBackIcon className="back-icon" fontSize="small" />
        Back to Home
      </Link>
    </div>
  );
};

export default BackToHomeButton;
